@import "abstracts/_abstracts";
.ListCategoriesHp {
    $block: &;
    padding: 4rem 0;

    &--subpage {
        padding: 0;
    }

    &-tabContent {
        list-style-type: none;
        margin-left: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        padding: 1.5rem 0;

        @include media('<=xs') {
            padding: 0;
            grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
        }

        #{$block}--subpage & {
            grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
        }
    }

    &-title {
        font-size: clamp(1.5rem, 3vw, 2rem);
        line-height: 1.25;

        #{$block}--subpage & {
            display: none;
        }
    }

    &-frame {
        #{$block}--subpage & {
            padding: 0;
        }
    }
}